import { Grid, Typography } from '@mui/material';
import SpeakerCard from './SpeakerCard';

const Speakers = (props) => {
  const { data: speakersArray } = props;
  return (
    <Grid container sx={{ background: 'linear-gradient(to right, #0e141f 0%, #0e141f 75%, #353744 100%)', pb: { xs: '20%', md: 0, sm: '12%' } }}>
      <Grid item md={12} sm={12} xs={12} sx={{ ml: { md: '100px', xs: '30px', sm: '50px' }, mt: '30px' }}>
        <Typography variant='h3' sx={{ fontWeight: 'bold', fontSize: { xs: '32px', md: '48px', color: '#fff' } }}>
          Speakers
        </Typography>
      </Grid>
      {speakersArray?.map((speaker, index) => (
        <Grid item key={index} lg={4} md={4} sm={6} xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: { xs: '0', md: 0, sm: 0 } }}>
          <SpeakerCard speakerDetail={speaker} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Speakers;
